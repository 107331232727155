// i18next-extract-mark-ns-start products-vectis-thermal

import { VECTIS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductVectisThermalManagementPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							New hybrid and clean fuel systems place more rigorous demands on thermal management.
							The ability to quickly and automatically create meshes enables fast, accurate Conjugate Heat Transfer (CHT)
							analysis to identify and optimise thermally critical locations.
						</p>

						<h2>Key features</h2>

						<ul>
							<li>Extensive surface geometry pre-processing tools including Boolean functions</li>
							<li>Large detailed meshes created automatically</li>
							<li>Prediction of boiling and resulting heat transfer</li>
							<li>Easy set-up of component models, such as fans, turbines and radiators</li>
							<li>Fully energy coupled Conjugate Heat Transfer (CHT) solver</li>
							<li>Extended thermal data post processing as well as option
								for direct export of CFD results for use in Finite Element
								(FE) analysis tools such as Realis FEARCE-Vulcan</li>
						</ul>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="VECTIS CHT" />
							<small>CHT analysis of 4-cylinder Diesel engine</small>
						</p>
					)}
				</div>

				<Aside>
					<Aside.Widget
						title="Capabilities and applications"
						className="widget__sibling__pages">
						<AssetList>
							{VECTIS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductVectisThermalManagementPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-vectis-thermal", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS.jpg" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_CHT.png" }) {
		...imageBreaker
	}
}
`;

